import * as React from "react";
import "./styles.scss";
import { Link } from "gatsby";
import ShareActionBlock from "../ShareActionBlock/ShareActionBlock";
import { footerItems } from "../constants";

export default function Footer({ hideFooterNavigation, hideSocial }) {
    return (
        <div className="footer">
            <img
                src="/images/glide/glide-logo.svg"
                className="glide-logo"
                alt="" />

            {!hideSocial && <ShareActionBlock />}

            {!hideFooterNavigation && (
                <div className="accordion-tabs">
                    {footerItems?.map((item, index) => (
                        <Link
                            key={index}
                            to={item.link}
                        >
                            {item.text}
                        </Link>
                    ))}
                </div>
            )}

            <a href="https://secure.nspcc.org.uk/?utm_source=partnerships&utm_medium=QR&utm_campaign=CP354%2a&utm_content=batterseapowerstationicerink%20poster%20Nov24&ac=255313"
                className="logo-container" target="_blank" rel="noreferrer">
                <img
                    src="/images/glide/nspcc-logo.svg"
                    className="nspcc-logo"
                    alt="" />
            </a>
            <p className="powered-by">
                Powered by
                <img
                    src="/images/fabacus-logo-white.png"
                    className="logo"
                    alt="Fabacus" />
            </p>
        </div>
    );
}
