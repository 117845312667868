//homepage
export const bannerList = [
    {
        image: '/images/glide/home-1.png',
        text: 'View all gifts & experiences',
        location: '/event-promotions'
    },
    {
        image: '/images/glide/home-2.png',
        text: 'What’s on at glide',
        location: 'https://xela.co/s/glc_out_on',
        newWindow: true,
        isExternal: true
    },
    {
        image: '/images/glide/home-3.png',
        text: 'Explore delicious Christmas Cocktails',
        location: 'https://glidebatterseapowerstation.co.uk/remy-cocktail-recipes/',
        newWindow: true,
        isExternal: true
    },
    {
        image: '/images/glide/home-4.png',
        text: 'Find your way around glide',
        location: '/map-preview'
    },
]

//gifts & experiences
export const giftsList = [
    {
        image: '/images/glide/gifts-1.png',
        text: '2 VIP tickets to Kisstory',
    },
    {
        image: '/images/glide/gifts-2.png',
        text: 'Free tickets to Taste of London 2025',
    },
    {
        image: '/images/glide/gifts-3.png',
        text: 'Tickets for 2 to Winter Wonderland',
    }
]

//carousel
export const rewardsList = [
    {
        image: '/images/glide/carousel-1.png',
        title: "Discounts on Ice Skating at Glide",
    },
    {
        image: '/images/glide/carousel-2.png',
        title: "Offers from Sticks'n'sushi ",
    }
]

//footer
export const footerItems = [
    {
        link: '/contacts',
        text: 'Contact Us'
    },
    {
        link: '/terms',
        text: ' Terms and Conditions'
    },
    {
        link: '/privacy',
        text: 'Privacy Policy'
    },
    {
        link: '/cookies',
        text: 'Cookie Policy'
    }
]

export const shareIcons = [
    {
        link: 'https://www.facebook.com/glideatbps/',
        icon: '/images/social-icons/facebook.svg'
    },
    {
        link: 'https://twitter.com/glideatbps',
        icon: '/images/social-icons/twitter.svg'
    },
    {
        link: 'https://www.tiktok.com/@glideatbps',
        icon: '/images/social-icons/tiktok.svg'
    },
    {
        link: 'https://www.instagram.com/glideatbps/',
        icon: '/images/social-icons/instagram.svg'
    }
]

//header
export const headerMenuItems = [
    { title: "Explore Glide", path: '/home' },
    { title: "Unlock my gift", path: '/event-promotions' },
    { title: "Find your way around", path: '/map-preview' },
    { title: "T&C", path: '/terms' },
]

//form
export const ethnicityOptions = [
    'Asian/Asian British',
    'Black/African/Caribbean/Black British',
    'White',
    'Other',
    'Prefer not to say'
]

export const travelOptions = [
    'Tube',
    'Train',
    'Taxi',
    'Boat',
    'Car',
    'Walking',
    'Other'
]

export const genderList = [
    'Male',
    'Female',
    'Non-binary/ third gender',
    'A gender not listed',
    'I use another term',
    'Prefer not to say '
]

export const yesNoOptions = [
    'Yes',
    'No'
]