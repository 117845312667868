import React, { useEffect, useState } from "react";
import MainTitle from "../MainTitle/MainTitle";
import "./styles.scss";
import PageSummary from "../PageSummary/PageSummary";

export default function ErrorMessage() {
    const [content, setContent] = useState("");

    useEffect(() => {
        const urlParams =
            typeof window !== "undefined"
                ? new URLSearchParams(window.location.search)
                : null;

        if (urlParams && urlParams.get("content")) {
            setContent(urlParams.get("content"));
        } else {
            setContent(`We are really sorry but the page you’re looking for doesn’t exist. Contact us on glidebattersea@help.xela.co.`);
        }
    }, []);

    return (
        <>
            <MainTitle className="error-title">Sorry!</MainTitle>

            <PageSummary>{content}</PageSummary>

            <div className="contact-email">
                <a className="contact-email" href={`mailto:${process.env.GATSBY_CONTACT_EMAIL}`}>
                    Contact Us
                </a>
            </div>
        </>
    );
}
