import * as React from "react"
import "./styles.scss";
import { shareIcons } from "../constants";

export default function ShareActionBlock() {
    return (
        <div className="share-with-friends-block">
            <div className="share-block-action">
                <div className="block-content">
                    <h2>Share with friends</h2>
                </div>
                <div className="share-item-list">
                    {shareIcons?.map((item, index) => (
                        <div key={index} className="share-item">
                            <a href={item.link} target="_blank" rel="noreferrer">
                                <img src={item.icon} alt="" />
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
