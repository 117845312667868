import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import "./styles.scss";
import Database from "../../_utils/Database";
import HeaderMenu from "../HeaderMenu/HeaderMenu";
import BurgerIcon from "../../../static/images/icons/burger-menu.svg"

const Header = () => {
    const [isLogged, setIsLogged] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);

    useEffect(() => {
        if (Database.getUserToken()) {
            setIsLogged(true);
        }
    }, [isLogged]);

    return (
        <nav className="header">
            <div className="header-content">
                <div className="menu">
                    <button
                        className="btn-menu"
                        onClick={() => setOpenMenu(true)}>
                        <img
                            src={BurgerIcon}
                            alt="menu-icon"
                        />
                    </button>
                </div>

                <Link to="/home" className="logo-wrap">
                    <img
                        src="/images/glide/glide-logo.svg"
                        className="logo"
                        alt="Glide"
                    />
                </Link>

                <HeaderMenu
                    openMenu={openMenu}
                    setOpenMenu={setOpenMenu}
                />
            </div>
        </nav>
    );
};
export default Header;
