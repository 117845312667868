import React from "react";
import CookiesMessage from "../CookiesMessage/CookiesMessage";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "../../_styles/index.scss";
import "./styles.scss";

const Layout = ({
    title,
    children,
    hideCookies,
    hideSubNav,
    hideFooterNavigation,
}) => {
    return (
        <>
            <title>
                {title ? `${title} | Glide at Battersea Power Station` : `Glide at Battersea Power Station`}
            </title>

            <main className="container layout-container">
                <Header hideSubNav={hideSubNav} />

                <div id="content">
                    {children}
                </div>

                {!hideCookies && <CookiesMessage />}

                <Footer hideFooterNavigation={hideFooterNavigation} />
            </main>
        </>
    );
};

export default Layout;
