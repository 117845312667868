import React from "react"
import { navigate } from "gatsby"
import CloseIcon from "../../../static/images/icons/close-icon.svg"
import { headerMenuItems } from "../constants"
import "./styles.scss"

const HeaderMenu = (props) => {
    const { openMenu, setOpenMenu } = props

    const path = typeof window !== 'undefined' ? window.location.pathname : ''

    const handleNavigate = (item) => {
        if (item.path === path) {
            setOpenMenu(false)
        } else navigate(item.path)
    }

    return (
        !!openMenu && (
            <div className="navigation">
                <div className="close">
                    <button
                        onClick={() => setOpenMenu(false)}
                    >
                        <img
                            src={CloseIcon}
                            alt="close-icon"
                        />
                    </button>
                </div>
                <div className="nav-menu">
                    {headerMenuItems.map((item, index) => (
                        <div key={index}>
                            {!!item?.isExternal ? (
                                <a target="_blank" href={item.path} rel="noreferrer">{item.title}</a>
                            ) : (!item.hide &&
                                <button className="btn btn-link" onClick={() => handleNavigate(item)}>
                                    {item.title}
                                </button>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        )
    )
}

export default HeaderMenu
